import React from "react";
import GetTouch from "./GetTouch";

function Home() {
  return (
    <>
      <div className="digitalagency" id="home">
        <div className="textdigital main_below">
          A digital agency with a human touch.
        </div>
        <div className="Enhancing reEnhancing">
          Enhancing operational efficiency through the <br></br>
          use of
          <span className="Agile reAgile">Agile Software Development</span>
        </div>
        <div className="Imagination reImagination">
          Imagination bleeds from the pen of creativity.
        </div>
        <GetTouch />
      </div>
    </>
  );
}

export default Home;
