import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import web from "../image/webapex.png";
import { Box, Button, Modal } from "@mui/material";
import Carrer from "../Component/Career";
import GetQuats from "../Component/GetQuats";

const navigationLinks = [
  { name: "Home", href: "#home" },
  { name: "About us", href: "#aboutus" },
  { name: "Services", href: "#services" },
  { name: "Technology", href: "#expertise" },
  { name: "Contact Us", href: "#contact" },
];

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: 50,
  },
  appbar: {
    background: "transparent",
    boxShadow: "none",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Navbar = () => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = React.useState(false);
  const handleOpen = () => setOpenn(true);
  const handleClose = () => setOpenn(false);
  return (
    <>
      <AppBar position="static" className={styles.appbar}>
        <Container maxWidth="fixed">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "inherit",
            }}
          >
            <div>
              <img src={web} alt="" height="200px" width="250px"></img>
            </div>
            <div>
              <Toolbar disableGutters style={{ marginTop: "35px" }}>
                <Hidden smDown>
                  {navigationLinks.map((item) => (
                    <>
                      <Link
                        className={styles.link}
                        id="animact"
                        marginTop="50px"
                        color="textPrimary"
                        variant="button"
                        underline="none"
                        href={item.href}
                        key={item.name}
                      >
                        {item.name}
                      </Link>
                    </>
                  ))}
                  <Button
                    id="animact"
                    style={{ marginRight: "25px", color: "black" }}
                    onClick={handleOpen}
                  >
                    CARRER
                  </Button>
                  <Modal
                    open={openn}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{ ...style, borderRadius: "20px" }}>
                      <Carrer />
                    </Box>
                  </Modal>

                  <Hidden smDown>
                   <GetQuats/>
                  </Hidden>
                </Hidden>

                <Hidden mdUp>
                  <IconButton onClick={() => setOpen(true)}>
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Toolbar>
            </div>
          </div>

          <SwipeableDrawer
            anchor="down"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            <div
              onClick={() => setOpen(false)}
              onKeyPress={() => setOpen(false)}
              role="button"
              tabIndex={0}
            >
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {navigationLinks.map((item) => (
                <ListItem key={item.name}>
                  <Link
                    className={styles.link}
                    color="textPrimary"
                    variant="button"
                    underline="none"
                    href={item.href}
                  >
                    {item.name}
                  </Link>
                </ListItem>
              ))}

              <div className="getquote">
                <div>Get Quote</div>
              </div>
            </List>
          </SwipeableDrawer>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
