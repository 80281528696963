import React from "react";
import webwhite from "../image/webwhite.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box,Modal } from "@mui/material";
import Carrer from "../Component/Career";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
 
};

const Footer = () => {
  const [openn, setOpenn] = React.useState(false);
  const handleOpen = () => setOpenn(true);
  const handleClose = () => setOpenn(false);
  return (
    <div className="fotter_main" id="contact">
      <div className="futtar_logo">
        <div>
          <img
            src={webwhite}
            alt=""
            height="200px"
            width="250px"
            className="img_logo_rewhite"
          ></img>
        </div>
        <div className="webapexfoot">
          WebApex is “Where creativity meets Innovation”. WebApex offers you the
          results of a perfect combination of functionality. Start building your
          website Today!
        </div>
      </div>
      <div className="futtar_contain">
        <div className="service_main">
          <div className="service">Services</div>
          <div className="webdev">Web Development</div>
          <div className="webdev">Web Design</div>
          <div className="webdev">UI/UX Design</div>
          <div className="webdev">Android App</div>
          <div className="webdev">App Marketing</div>
        </div>
        <div className="Quick_main">
          <div className="service">Quick Links</div>
          <div className="webdev">
            <a href="#aboutus">About us</a>
          </div>
          <div className="webdev">
            <a href="#services">Services</a>
          </div>
          <div className="webdev">
            <a href="#contact">Contact us</a>
          </div>
          <div className="webdev">
            <a href="#expertise">Technology</a>
          </div>
          <div>
            <div onClick={handleOpen}>
              <div className="webdev">Carrer</div>
            
            </div>
            <Modal
              open={openn}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ ...style, borderRadius: "20px" }}>
                <Carrer />
              </Box>
            </Modal>
          </div>
        </div>
        <div className="Visit_main">
          <div className="service">Visit Us</div>
          <div className="adress">
            ABC 2, Near sudama chowk,
            <br />
            Mota varachha, Surat-399 101
          </div>
          <div className="looking">Looking for collaboration ?</div>
          <div className="hremail">hr@webapex.in</div>
          <div className="icon_font">
            <div>
              <InstagramIcon
                className="reicon_img"
                style={{ fontSize: "36px", color: "white" }}
              />
            </div>
            <div>
              <FacebookTwoToneIcon
                style={{ fontSize: "36px", color: "white" }}
              />
            </div>
            <div>
              <TwitterIcon style={{ fontSize: "36px", color: "white" }} />
            </div>
            <div>
              <LinkedInIcon style={{ fontSize: "36px", color: "white" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
