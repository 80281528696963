import "./styles.css";
import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import cardimg from "../image/cardimg.png";
import cardimgg from "../image/img13.jpg";
import cardimgf from "../image/img14.jpg";
import cardimgn from "../image/img15.webp";

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";

export default function Services() {
  const cardData = [
    {
      id: 1,
      image: cardimg,
      title: "Web Development",
      subTitle: "Create unique & high-end web design with WebApex",
      discription:
        "We offer various web designing services such as graphic design, responsive and dynamic web design, web portal design, template design, customized web design, and much more.",
    },
    {
      id: 2,
      image: cardimgg,
      title: "UI/UX Design, Mobile App Development",
      subTitle: "Build an amazing application with WebApex Solutions.",
      discription:
        "We have a qualified team of UI/UX specialists and experts to create innovative, functional, and visually engaging designs.",
    },
    {
      id: 3,
      image: cardimgf,
      title: "Web Development",
      subTitle: "CreateAnd grow your website with WebApex Solutions",
      discription:
        "We work collaboratively to create websites that are both <br /> visually stunning and highly functional, ensuring that your online presence stands out.",
    },
    {
      id: 2,
      image: cardimgn,
      title: "UI/UX Design, Mobile App Development",
      subTitle: "Build an amazing application with WebApex Solutions.",
      discription:
        "We have a qualified team of UI/UX specialists and experts to create innovative, functional, and visually engaging designs.",
    },
  ];

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imgIndex, setImgIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImgIndex(next),
  };
  return (
    <>
      <div className="main_ourservice" id="services">
        <div className="ourservices">Our Services</div>
        <div>
          <p className="trasform_idea">
            Transform Your Ideas into Reality with Our Software Services. We
            provide innovative
            <br />
            solutions to make your life easier. Our cutting-edge solutions
            simplify complex
            <br />
            processes and enhance productivity, delivering optimal results that
            <br />
            make your life easier
          </p>
        </div>
      </div>
      <div className="Appqw">
        <Slider {...settings}>
          {cardData.map((img, idx) => (
            <div className={idx === imgIndex ? "slide activeSlide" : "slide"}>
              <div className="cardsub">
                <div className="blurediv">
                  <Card className="card_comp recard_comp">
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="280"
                        image={img.image}
                        alt="green iguana"
                      />
                      <CardContent className="card_content">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          style={{ textAlign: "center", fontSize: "25px" }}
                        >
                          {img.title}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          style={{ textAlign: "center", fontSize: "15px" }}
                        >
                          {img.subTitle}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          {img.discription}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            marginBottom: "15px",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ background: "#02474E" }}
                          >
                            Read More
                            <ArrowForwardTwoToneIcon
                              style={{ marginLeft: "10px" }}
                            />
                          </Button>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
