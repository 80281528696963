import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Home from "./Home";
import AboutUS from "./AboutUS";
import Expertise from "./Expertise";
import Services from "./Services";


const Main = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="webpex_main">
        <Navbar />
        <Home />
      </div>
      <div className="main_howwork">
        <AboutUS />
        <Expertise />
        <Services />
        <Footer />
       
      </div>
    </div>
  );
};

export default Main;
