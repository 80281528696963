import React from "react";
import angular from "../image/Angular.png";
import react from "../image/react.png";
import node from "../image/Node.png";
import blockchain from "../image/Blockchain.png";
import meeting from "../image/meeting.png";

export default function Expertise() {
  return (
    <>
      <div className="main_metting" id="expertise">
        <div className="image_mett_ing">
          <img src={meeting} alt="" className="img_metting_third" />
        </div>
        <div className="image_mett">
          <div className="tech_expert">Technologies We Expertise</div>
          <div>
            <p className="ourexperiance">
              Our experienced developers leverage these technologies to create
              robust,
              <br />
              secure and scalable applications that provide value to our
              customers.
              <br />
              We are using the latest technology to automate mundane
              <br />
              tasks and streamline processes
            </p>
          </div>
          <div className="card_tech">
            <div className="react_card">
              <div className="filter_img multi_card">
                <div>
                  <img src={angular} alt=""></img>
                </div>
                <div>Anguler</div>
              </div>
              <div className="filter_img multi_card">
                <div>
                  <img src={react} alt=""></img>
                </div>
                <div>React</div>
              </div>
            </div>
            <div className="react_card">
              <div className="filter_img multi_card">
                <div>
                  <img src={node} alt=""></img>
                </div>
                <div>Node Js</div>
              </div>
              <div className="filter_img multi_card">
                <div>
                  <img src={blockchain} alt=""></img>
                </div>
                <div>Blockchain</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
