import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const GetTouch = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="schedual reschedual">
      <Button
        onClick={handleOpen}
        style={{ color: "white", fontFamily: "Inter", fontStyle: "normal" }}
      >
        Get in touch
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, borderRadius: "100px" }}>
          <Typography
            className="getintouch"
            id="modal-modal-title"
            style={{
              fontSize: "35px",
              fontWeight: "bold",
              color: "#02474e",
            }}
          >
            Get in Touch
          </Typography>
          <Typography
            className="getintouch"
            id="modal-modal-description"
            style={{ color: "GrayText" }}
          >
            We provide digital experience services to startups and small
            businesses.
          </Typography>
          <div className="main_form">
            <div className="sub_form">
              <div className="p_form">
                <Box
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    margin: "10px",
                  }}
                >
                  <TextField fullWidth label="First Name" id="fullWidth" />
                </Box>
                <Box
                  sx={{
                    width: 500,
                    height: "10px",
                    maxWidth: "100%",
                    margin: "10px",
                  }}
                >
                  <TextField fullWidth label="Last Name" />
                </Box>
              </div>

              <div className="">
                <Box
                  sx={{
                    width: 660,
                    maxWidth: "100%",
                    margin: "20px",
                  }}
                >
                  <TextField
                    fullWidth
                    type="email"
                    label="Email Address"
                    id="fullWidth"
                  />
                </Box>
              </div>

              <div className="">
                <Box
                  sx={{
                    width: 660,
                    maxWidth: "100%",
                    margin: "20px",
                  }}
                >
                  <TextField
                    fullWidth
                    type="number"
                    label="Phone Number "
                    id="fullWidth"
                  />
                </Box>
              </div>

              <div className="">
                <Box
                  sx={{
                    width: 660,
                    maxWidth: "100%",
                    margin: "20px",
                  }}
                >
                  <TextField
                    fullWidth
                    rows={3}
                    multiline
                    label="Leave us a Message"
                    id="fullWidth"
                  />
                </Box>
              </div>
              <div className="">
                <Box
                  sx={{
                    width: 660,
                    maxWidth: "100%",
                    margin: "20px",
                    color: "grey",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked style={{ color: "#02474e" }} />
                    }
                    label="You agree to our friendly"
                  />
                </Box>
              </div>

              <div className="">
                <Box
                  sx={{
                    width: 660,
                    maxWidth: "100%",
                    margin: "20px",
                    color: "grey",
                  }}
                >
                  <Button
                    style={{ width: 660, backgroundColor: "#02474e" }}
                    variant="contained"
                  >
                    Send Message
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default GetTouch;
