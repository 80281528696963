import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import card from "../image/card1.png";
import card2 from "../image/card2.png";
import card3 from "../image/card3.png";
import card4 from "../image/card4.png";
function AboutUS() {
  return (
    <>
      <div className="how_work_text" id="aboutus">
        How We Work?
      </div>
      <div className="no_metter_text">
        No matter what your requirement is, Mobile App and web development,
        <br></br>
        we have a specific approach which comprises of the following{" "}
      </div>
      <div className="main_card">
        <div className="card_sub">
          <div className="recard">
            <div>
              <Card
                className="animaction"
                style={{ width: "300px", borderRadius: "25px" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card4}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ textAlign: "center" }}
                    >
                      Discover
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                    >
                      We shape brands through <br />
                      exploration, applying in- depth <br />
                      research to challenge
                      <br />
                      assumptions at every turn.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card
                className="animaction"
                style={{ width: "300px", borderRadius: "25px" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ textAlign: "center" }}
                    >
                      Build
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                    >
                      Using modern technologies, we
                      <br />
                      build with efficiency and skill,
                      <br />
                      creating flexible and scalable
                      <br />
                      business-driven solutions.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
          <div className="recard">
            <div>
              <Card
                className="animaction"
                style={{ width: "300px", borderRadius: "25px" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card3}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ textAlign: "center" }}
                    >
                      Desing
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                    >
                      Our design approach is simple.
                      <br />
                      We embrace the joy of creating
                      <br />
                      something unique that is easy
                      <br />
                      for end users.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div>
              <Card
                className="animaction"
                style={{ width: "300px", borderRadius: "25px" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card2}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ textAlign: "center" }}
                    >
                      Deliver
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                    >
                      We take an iterative approach <br /> to both our work and
                      practice,
                      <br /> always looking for ways to
                      <br /> improve what we do.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUS;
