import "./App.css";
import * as React from "react";
import Main from "./Component/Main";

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
