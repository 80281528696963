import { Button } from "@mui/material";
import React from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import meeting from "../image/icon1.png";

const Career = () => {
  return (
  
      <div>
        <div className="take_look">Take a look at</div>
        <div className="current_open">Current Openings...</div>
      
          <div className="carrer_flex">
            <div className="carrer_fl">
              <div className="image_icon">
                <img
                  src={meeting}
                  alt=""
                  width="50px"
                  height="50px"
               
                ></img>
              </div>
              <div className="">
                <div className="react_js">ReactJs Developer</div>
                <div className="experiance">
                  <div className="experiance_sub experiance_next">
                    <div className="experiance_text">Experiance:</div>
                    <div className="experiance_years"> 1 - 2 Years</div>
                  </div>

                  <div className="experiance_sub experiance_next">
                    <div className="experiance_text">No. of Openings:</div>
                    <div className="experiance_years">0</div>
                  </div>

                  <div className="experiance_sub">
                    <div className="experiance_text">Qualification:</div>
                    <div className="experiance_years">
                      B.Tech/ MCA/BCA/ M. Sc (IT)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingRight: "10px" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: " #02474e", height: "45px" }}
                size="large"
              >
                Closed
                <ArrowCircleRightOutlinedIcon style={{ marginLeft: "20px" }} />
              </Button>
            </div>
          </div>

          <div className="carrer_flex">
            <div className="carrer_fl">
              <div className="image_icon">
                <img
                  src={meeting}
                  alt=""
                  width="50px"
                  height="50px"
                
                ></img>
              </div>
              <div className="">
                <div className="react_js">NodeJs Developer</div>
                <div className="experiance">
                  <div className="experiance_sub experiance_next">
                    <div className="experiance_text">Experiance:</div>
                    <div className="experiance_years"> 2 - 4 Years</div>
                  </div>

                  <div className="experiance_sub experiance_next">
                    <div className="experiance_text">No. of Openings:</div>
                    <div className="experiance_years">0</div>
                  </div>

                  <div className="experiance_sub">
                    <div className="experiance_text">Qualification:</div>
                    <div className="experiance_years">
                      B.Tech/ MCA/BCA/ M. Sc (IT)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingRight: "10px" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: " #02474e", height: "45px" }}
                size="large"
              >
                Closed
                <ArrowCircleRightOutlinedIcon style={{ marginLeft: "20px" }} />
              </Button>
            </div>
          </div>

          <div className="carrer_flex">
            <div className="carrer_fl">
              <div className="image_icon">
                <img
                  src={meeting}
                  alt=""
                  width="50px"
                  height="50px"
                 ></img>
              </div>
              <div className="">
                <div className="react_js">Angular Developer</div>
                <div className="experiance">
                  <div className="experiance_sub experiance_next">
                    <div className="experiance_text">Experiance:</div>
                    <div className="experiance_years"> 2 - 4 Years</div>
                  </div>

                  <div className="experiance_sub experiance_next">
                    <div className="experiance_text">No. of Openings:</div>
                    <div className="experiance_years">0</div>
                  </div>

                  <div className="experiance_sub">
                    <div className="experiance_text">Qualification:</div>
                    <div className="experiance_years">
                      B.Tech/ MCA/BCA/ M. Sc (IT)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingRight: "10px" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: " #02474e", height: "45px" }}
                size="large"
              >
                Closed
                <ArrowCircleRightOutlinedIcon style={{ marginLeft: "20px" }} />
              </Button>
            </div>
          </div>
        </div>
    
  
  );
};

export default Career;
